<template>
    <div>
        <v-container>
            <v-row
                dense
            >
                <v-col
                    cols="12"
                >
                    <v-card
                        :loading="loadingData"
                    >
                        <v-card-title>
                            Ricevute di {{ loggedUserCompany.name }}
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :headers="invoiceHeaders"
                                :items="mappedInvoicesList"
                                :footer-props="{
                                    itemsPerPageText: 'Righe per pagina:',
                                    itemsPerPageAllText: 'Tutte',
                                    itemsText: 'righe',
                                    ofText: 'di',
                                    pageText: 'Pagina',
                                    prevText: 'Precedente',
                                    nextText: 'Successiva',
                                    rowsPerPageText: 'Righe per pagina',
                                    noResultsText: 'Nessun risultato',
                                    rowsText: 'righe'
                                }"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-btn
                                        class="ma-2"
                                        color="green"
                                        dark
                                        small
                                        :href="item.hosted_invoice_url"
                                        target="_blank"
                                    >
                                        <v-icon dark>
                                            mdi-cloud-download
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: "invoices",
    components: {},
    computed: {
        ...mapGetters(['loggedUser','invoicesList','loggedUserCompany']),
        mappedInvoicesList() {
            return this.invoicesList.map(invoice => {
                return {
                    ...invoice,
                    createdAtFormatted: moment.unix(invoice.created).format('DD/MM/YYYY'),
                    totalFormatted: (invoice.total)/100 + ' €',
                    statusFormatted: this.statusText(invoice.status),
                }
            })
        }
    },
    data() {
        return {
            loadingData: true,
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Ricevute',
                    to: '/invoices',
                }
            ],
            loading:{0:false},
            invoiceHeaders: [
                {
                    text: 'Data',
                    align: 'left',
                    sortable: true,
                    value: 'createdAtFormatted',
                },
                {
                    text: 'Importo',
                    align: 'left',
                    sortable: false,
                    value: 'totalFormatted',
                },
                {
                    text: 'Stato',
                    align: 'left',
                    sortable: false,
                    value: 'statusFormatted',
                },
                {
                    text: 'Azioni',
                    align: 'right',
                    sortable: false,
                    value: 'actions',
                },
            ],
        }
    },
    async mounted() {
        await this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
        await this.$store.dispatch('getInvoices')
        if(this.invoicesList.length === 0){
            await this.$store.dispatch('getInvoices')
        }
        this.loadingData = false
    },
    methods: {
        statusText(value){
            switch(value){
                case 'draft':
                    return 'Non emessa'
                case 'open':
                    return 'In attesa di pagamento'
                case 'paid':
                    return 'Pagata'
                case 'void':
                    return 'Emessa per errore'
                case 'uncollectible':
                    return 'Scaduta'
            }

            return false
        },
        setLoading(idx){
            if(this.loading[idx] === undefined){
                this.$set(this.loading,idx,true);
            }else{
                this.loading[idx] = !this.loading[idx];
            }
        },
    },
}
</script>