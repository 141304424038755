import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"loading":_vm.loadingData}},[_c(VCardTitle,[_vm._v(" Ricevute di "+_vm._s(_vm.loggedUserCompany.name)+" ")]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.invoiceHeaders,"items":_vm.mappedInvoicesList,"footer-props":{
                                itemsPerPageText: 'Righe per pagina:',
                                itemsPerPageAllText: 'Tutte',
                                itemsText: 'righe',
                                ofText: 'di',
                                pageText: 'Pagina',
                                prevText: 'Precedente',
                                nextText: 'Successiva',
                                rowsPerPageText: 'Righe per pagina',
                                noResultsText: 'Nessun risultato',
                                rowsText: 'righe'
                            }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c(VBtn,{staticClass:"ma-2",attrs:{"color":"green","dark":"","small":"","href":item.hosted_invoice_url,"target":"_blank"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-cloud-download ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }